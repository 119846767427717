import api from '../index'

const canteen = {
    // 获取食堂-菜品列表
    getDishesList(data) {
        return api({
            url: 'v2/ofs/dishes-list',
            method: 'post',
            data: data
        })
    },
    // 获取食堂-套餐列表
    getMealList(data) {
      return api({
          url: 'v2/ofs/meal-list',
          method: 'post',
          data: data
      })
    },
}
export default canteen
