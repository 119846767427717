import api from '../index'

const shopCart = {
    // 获取购物车列表
    getShopCartList(data) {
        return api({
            url: 'v2/ofs/shopping-cart-list',
            method: 'post',
            data: data
        })
    },
    editShopCart(data) {
        return api({
            url: 'v2/ofs/shopping-cart-edit',
            method: 'post',
            data: data
        })
    },

}
export default shopCart
